import ToDoMain from "../todoApp/components/main"

const ToDoList = () => {
    return(
        <>
            <ToDoMain />
        </>
    )
}

export default ToDoList